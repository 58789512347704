@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+3&display=swap');

.access-code {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: rgba(40, 121, 177, 0.15);
  border-radius: 0;
  border-width: 0;border-bottom: dashed rgba(0, 0, 0, 0.20);
  color: #000;
  text-align: left;
}

.sidebar-b {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  background: linear-gradient(92deg, rgba(226, 236, 251, 0.50) 0.11%, rgba(252, 244, 224, 0.50) 100%), linear-gradient(180deg, rgba(240, 227, 250, 0.50) 0%, rgba(255, 235, 244, 0.50) 100%), #FFF;
  box-shadow: 0px 0px 4px #00000040;
  display: flex;
  padding: 64px 64px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  transition: 0.4s;
}

.sidebar-b .group {
  height: 43.4px;
  margin-left: -1px;
  margin-right: -3px;
  position: relative;
  width: 254px;
}

.sidebar-b .text-wrapper {
  color: #333;
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 35px;
  margin-left: 8px;
}

.sidebar-b .image {
  width: 140px;
  height: 43px;
  flex-shrink: 0;
}

.sidebar-b .content {
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.sidebar-b .tagline {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.primary-button {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #206EA5;
  color: #FFFFFF;
  border: 0;
}

.primary-button .label {
  color: #ffffff;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3.2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.primary-button:disabled {
  background-color: #206ea5;
  opacity: 0.2;
}

.primary-button.default {
  background-color: #206ea5;
}

.primary-button.hover {
  background-color: #105484;
}

.textarea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 320px;
}

.textarea .label-helper-text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.textarea .label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: #333;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textarea .required {
  align-items: center;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-color: #cc3300;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.textarea .text-wrapper {
  color: #cc3300;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.textarea .div {
  color: #000000;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.textarea .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.textarea .helper-text {
  color: #00000099;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.textarea .input-error-message {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.rectangle {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #00000033;
  border-radius: 4px;
  height: 80px;
  position: relative;
  width: 100%;
  padding: 5px 10px;
}

.textarea .error-message {
  color: #cc3300;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-field {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.text-field .label-helper-text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.text-field .label {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  position: relative;
  color: #333;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-field .required {
  align-items: center;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-color: #990000;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.asterick{
  color: #C30;
}

.text-field .text-wrapper {
  color: #cc3300;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-field .div {
  color: #000000;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-field .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.text-field .helper-text {
  color: #00000099;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-field .input-error-message {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.text-field .filled {
  align-items: center;
  background: none;
  border: none;
  color: #000000;
  display: inline-flex;
  flex: 0 0 auto;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  gap: 10px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  padding: 0;
  position: relative;
  width: 100%;
}

.text-field .error-message {
  color: #cc3300;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.form {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  margin-bottom: 64px;
}

.text-wrapper-3 {
  color: #206EA5;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-unit {
  display: flex;
  width: 320px;
  padding: 16px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
}

.product-unit-container {
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-b.scrolled {
  padding: 36px 48px;
  gap: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  background: linear-gradient(92deg, rgba(226, 236, 251, 0.50) 0.11%, rgba(252, 244, 224, 0.50) 100%), linear-gradient(180deg, rgba(240, 227, 250, 0.50) 0%, rgba(255, 235, 244, 0.50) 100%), #FFF;
}

.sidebar-b.scrolled .content {
  display: none;
}

.add-product-link {
  color: #206EA5;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-input {
  display: flex;
  height: 36px;
  padding: 0px 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  color: #333;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-section {
  color: #333;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-section {
  margin-top: 20rem;
}

.sans-pro {
  font-family: Source Sans Pro;
}

.sans-code-pro {
  font-family: "Source Code Pro";
}

.gap-8 {
  gap: 8px;
}
