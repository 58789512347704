.App {
  text-align: center;
  font-family: 'Source Sans 3';
}

.App-logo {
  height: 100px;
  width: 100px;
  pointer-events: none;
  margin-top: 43px;
}

.logo-back {
  background: #9898f2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  font-size: calc(10px + 2vmin);
  margin-top: 43px
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .d-small {
    display: block;
  }

  .d-large {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .d-small {
    display: none;
  }

  .d-large {
    display: block;
  }
}
